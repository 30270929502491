<template>
    <div>

        <div
            class="body"
            v-if="activeName === 0">

            <el-row :gutter="20">

                <el-col :span="8" v-for="(item, index) in configList" :key="index">

                    <div class="config_item">

                        <el-row>

                            <el-col :span="2">

                                <img :src="item.imgUrl" width="20">

                            </el-col>

                            <el-col :span="22">

                                <div class="config_item_title">
                                    {{ item.title }}
                                </div>

                                <div class="config_item_details">
                                    {{ item.details }}
                                </div>

                                <el-button type="text" style="padding: 0;margin-top: 10px;" @click="toItem(item.id)">
                                    {{$i18n.t('product.text192')}}
                                </el-button>

                            </el-col>

                        </el-row>

                    </div>

                </el-col>

            </el-row>

        </div>

        <!-- 配网引导 -->
        <guidance ref="guidance" v-else-if="activeName === 1" @close="activeName = 0"></guidance>

    </div>

</template>

<script>

import Guidance from "@/views/product/views/config/guidance";

export default {

    name: "config",

    components: {Guidance},

    data() {

        return {

            activeName: 0,

            configList: [
                {
                    id: 1,
                    imgUrl: require('../../../../assets/config/distribution_network.png'),
                    title: this.$i18n.t('product.text193'),
                    details: this.$i18n.t('product.text194'),
                },
                // {
                //     id: 2,
                //     imgUrl: require('../../../../assets/config/language_configuration.png'),
                //     title: '多语言配置',
                //     details: '设置展示给用户的各类文案，支持多语言配置'
                // },
                // {
                //     id: 3,
                //     imgUrl: require('../../../../assets/config/help_configuration.png'),
                //     title: '使用帮助配置',
                //     details: '配置产品的使用帮助文档'
                // },
                // {
                //     id: 4,
                //     imgUrl: require('../../../../assets/config/privacy_configuration.png'),
                //     title: '隐私政策配置',
                //     details: '根据产品的销售国家等配置合适的隐私政策、用户协议等'
                // }
            ]

        }

    },

    methods: {

        toItem(id) {
            switch (id) {
                case 1:
                    this.activeName = id
                    this.$nextTick(() => {
                        console.log(this.$refs)
                        this.$refs.guidance.getMsg();
                    })
                    break;
            }
        }

    }

}
</script>

<style scoped>
.body {
    width: calc(100% - 60px) !important;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 16px 20px;
    margin: 10px;
}


.config_item {
    border: 1px solid #CDCDCD;
    border-radius: 5px;
    padding: 10px;
}

.config_item_title {
    float: left;
    font-weight: bold;
    font-size: 14px;
}

.config_item_details {
    margin-top: 30px;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.40);
}

.el-col-8 {
    margin-top: 20px;
}

.el-col-8:nth-child(1),
.el-col-8:nth-child(2),
.el-col-8:nth-child(3) {
    margin-top: 0;
}
</style>
