<template>

    <div>

        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px'
            }"
            v-for="(item,index) in formList"
            :id="'card' + index"
            :key="index"
            shadow="always">

            <div slot="header" class="clearfix">
                <el-button type="text" icon="el-icon-circle-close" style="padding:0;" @click="delCard(index)"
                           v-show="formList.length != 1"></el-button>
            </div>

            <el-form :model="item" size="small" :rules="rules" ref="ruleForm" class="effect_form_item"
                     :label-width="formLabelWidth">

                <el-form-item :label="$i18n.t('product.text36')+'：'" prop="attributeName">
                    <el-input
                        v-model.trim="item.attributeName"
                        :placeholder="$i18n.t('product.text71')">
                    </el-input>
                </el-form-item>

                <el-form-item :label="$i18n.t('product.text37')+'：'" prop="attributeRemarks">
                    <el-input
                        v-model.trim="item.attributeRemarks"
                        :placeholder="$i18n.t('product.text72')">
                    </el-input>
                </el-form-item>

<!--                <el-form-item label="权限：" prop="authority">-->
<!--                    <el-radio-group v-model="item.authority">-->
<!--                        <el-radio :label="item1.id" v-for="(item1,index1) in $message.authorityList"-->
<!--                                  :disabled="item.format == 7 && (item1.id == 1 || item1.id == 3)" :key="index1">-->
<!--                            {{ item1.name }}-->
<!--                        </el-radio>-->
<!--                    </el-radio-group>-->
<!--                </el-form-item>-->

                <el-form-item :label="$i18n.t('product.text73')+'：'" prop="format">
                    <el-radio-group v-model="item.format" @change="clearForm(index)">
                        <el-radio :label="item1.id" :disabled="item1.id === 7" v-for="(item1,index1) in $message.formatList" :key="index1">
                            {{ item1.name }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item
                    :label="$i18n.t('product.text74')+'：'"
                    v-show="item.format !== 5 && item.format !== 6"
                    :prop="(item.format !== 5 && item.format !== 6) ? 'typeBytes' : ''">
                    <el-select v-model="item.typeBytes" :popper-append-to-body="false" :placeholder="$i18n.t('product.text75')" :disabled="item.format !== 0">
                        <el-option
                            v-for="item in $message.typeByteList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 数值型 -->
                <el-form-item :label="$i18n.t('product.text76')+'：'" required :error="item.err.valueErr"
                              v-show="item.format === 0">
                    <el-col :span="11">
                        <el-input-number
                            @blur="getFormList(index)"
                            v-model="item.startNum"
                            controls-position="right"
                            :min="item.typeBytes === 1 ? -128 : item.typeBytes === 2 ? -32768 : item.typeBytes === 4 ? -2147483648 : null"
                            :max="item.typeBytes === 1 ? 127 : item.typeBytes === 2 ? 32767 : item.typeBytes === 4 ? 2147863647 : null"
                            :precision="0"></el-input-number>
                    </el-col>
                    <el-col class="line" :span="2" style="text-align: center;">-</el-col>
                    <el-col :span="11">
                        <el-input-number
                            @blur="getFormList(index)"
                            v-model="item.endNum"
                            controls-position="right"
                            :min="item.typeBytes === 1 ? -128 : item.typeBytes === 2 ? -32768 : item.typeBytes === 4 ? -2147483648 : null"
                            :max="item.typeBytes === 1 ? 127 : item.typeBytes === 2 ? 32767 : item.typeBytes === 4 ? 2147863647 : null"
                            :precision="0"></el-input-number>
                    </el-col>
                </el-form-item>

                <!-- 整数型、小数型 -->
<!--                <el-form-item label="数值范围：" required :error="item.err.valueErr"-->
<!--                              v-show="item.format === 1 || item.format === 2 || item.format === 8 || item.format === 9">-->
<!--                    <el-col :span="11">-->
<!--                        <el-input-number-->
<!--                            @blur="getFormList(index)"-->
<!--                            v-model="item.startNum"-->
<!--                            controls-position="right"-->
<!--                            :min="item.format === 8 ? -32768 : item.format === 9 ? -2147483648 : null"-->
<!--                            :max="item.format === 8 ? 32767 : item.format === 9 ? 2147483647 : null"-->
<!--                            :precision="(item.format === 1 || item.format === 8 || item.format === 9) ? null : 7"></el-input-number>-->
<!--                    </el-col>-->
<!--                    <el-col class="line" :span="2" style="text-align: center;">-</el-col>-->
<!--                    <el-col :span="11">-->
<!--                        <el-input-number-->
<!--                            @blur="getFormList(index)"-->
<!--                            v-model="item.endNum"-->
<!--                            controls-position="right"-->
<!--                            :min="item.format === 8 ? -32768 : item.format === 9 ? -2147483648 : null"-->
<!--                            :max="item.format === 8 ? 32767 : item.format === 9 ? 2147483647 : null"-->
<!--                            :precision="(item.format === 1 || item.format === 8 || item.format === 9) ? null : 7"></el-input-number>-->
<!--                    </el-col>-->
<!--                </el-form-item>-->

                <el-form-item :prop="(item.format === 0) ? 'rate' : ''"
                              v-show="item.format === 0">

                    <span slot="label">
                        {{$i18n.t('product.text77')}}
                        <el-popover
                            placement="top-start"
                            title=""
                            width="200"
                            trigger="hover"
                            :content="$i18n.t('product.text78')">
                            <i
                                :class="objectIcon"
                                @mouseover="objectIcon = 'attribute_coicon icon-yuanyin'"
                                @mouseleave="objectIcon = 'attribute_alicon iconfont_al icon-yiwen'"
                                slot="reference"></i>
                        </el-popover>
                        :
                    </span>
                    <el-select v-model="item.rate" :popper-append-to-body="false" :placeholder="$i18n.t('product.text79')">
                        <el-option
                            v-for="item in $message.rateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item :label="$i18n.t('product.text80')+'：'" :error="item.err.stepErr" required
                              v-show="item.format === 0">
                    <el-input-number
                        @blur="getFormList(index)"
                        v-model="item.step"
                        :placeholder="$i18n.t('product.text81')"
                        :max="item.typeBytes === 1 ? 127 : item.typeBytes === 2 ? 32767 : item.typeBytes === 4 ? 2147863647 : null"
                        :min="0"
                        :precision="0"></el-input-number>
                </el-form-item>

                <el-form-item :label="$i18n.t('product.text82')+'：'" :prop="(item.format === 0) ? 'unit' : ''"
                              v-show="item.format === 0">
                    <el-input v-model.trim="item.unit" :placeholder="$i18n.t('product.text83')"></el-input>
                </el-form-item>

                <!-- 枚举型/故障型 -->
                <el-form-item :label="item.format === 3 ? $i18n.t('product.text84') : $i18n.t('product.text85')" required :error="item.err.enumErr"
                              v-if="item.format === 3 || item.format === 7">
                    <span v-for="(item1,index1) in item.enumList" :key="index1" class="emun_item">
                        <el-col :span="9">
                            <el-input maxlength="30" show-word-limit v-model.trim="item1.key"
                                      :placeholder="item.format === 3 ? $i18n.t('product.text86') : $i18n.t('product.text87')"></el-input>
                        </el-col>
                        <el-col :span="2" style="text-align: center">
                            —
                        </el-col>
                        <el-col :span="9">
                            <el-input maxlength="30" show-word-limit v-model.trim="item1.name"
                                      :placeholder="item.format === 3 ? $i18n.t('product.text88') : $i18n.t('product.text89')"></el-input>
                        </el-col>
                        <el-col :span="4" class="add_enum">
                            <el-button type="text" icon="el-icon-remove-outline" @click="delEmun(index,index1)"
                                       v-show="item.enumList.length > 1"
                                       style="color: red;margin-left: 0px;"></el-button>
                            <el-button type="text" style="color: #659400;"
                                       v-show="item.enumList.length < 30 && (index1 + 1) === item.enumList.length"
                                       icon="el-icon-circle-plus-outline" @click="addEmun(index)"></el-button>
                        </el-col>
                    </span>
                </el-form-item>

                <!-- 字符型/透传型 -->
                <el-form-item :label="$i18n.t('product.text90')" :error="item.err.stringErr" required
                              v-show="item.format === 5 || item.format === 6">
                    <el-input-number v-model="item.string" :placeholder="$i18n.t('product.text91') + (item.format === 5 ? 255 : 1000)" :max="(item.format === 5 ? 255 : 1000)"
                                     :min="0"></el-input-number>
                </el-form-item>

                <el-form-item :label="$i18n.t('product.text6')">
                    <el-input type="textarea" maxlength="200" show-word-limit :rows="4"
                              v-model="item.remarks"></el-input>
                </el-form-item>

            </el-form>

        </el-card>

    </div>

</template>

<script>

export default {

    computed: {
        form() {
            return {
                attributeName: "",
                attributeRemarks: "",
                // authority: 1,
                format: 0,
                rate: 0,
                remarks: "",
                enumList: [{
                    key: "",
                    name: ""
                }],
                startNum: undefined,
                endNum: undefined,
                step: undefined,
                unit: "",
                string: undefined,
                typeBytes: 1,
                err: {
                    valueErr: "",
                    enumErr: "",
                    stringErr: "",
                    stepErr: ""
                }
            }
        }
    },

    data() {

        return {

            formLabelWidth: '120px',

            objectIcon: "attribute_alicon iconfont_al icon-yiwen",

            rules: {
                attributeName: [
                    {required: true, message: this.$i18n.t('product.text71'), trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: this.$i18n.t('product.text23')},
                ],
                attributeRemarks: [
                    {required: true, message: this.$i18n.t('product.text92'), trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,32}$)[\S\s]*/, message: this.$i18n.t('product.text68')},
                    {pattern: /^[a-z_A-Z_0-9]*$/, message: this.$i18n.t('product.text69')},
                ],
                // authority: [{required: true, message: "请选择权限", trigger: "blur"}],
                format: [{required: true, message: this.$i18n.t('product.text93'), trigger: "blur"}],
                rate: [{required: true, message: this.$i18n.t('product.text79'), trigger: "blur"}],
                step: [
                    {required: true, message: this.$i18n.t('product.text94'), trigger: "blur"}
                ],
                typeBytes: [{required: true, message: this.$i18n.t('product.text75'), trigger: "blur"}],
                unit: [
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: this.$i18n.t('product.text23')},
                    {required: true, message: this.$i18n.t('product.text95'), trigger: "blur"}
                ]
            },

            formList: [],
            language: localStorage.getItem('language')

        }

    },

    mounted() {
        this.formLabelWidth = this.language === 'zh' ? '120px' : '180px'
        this.formList = [this.$config.deepCopy(this.form)]
    },

    methods: {

        addForm() {
            this.formList.push(this.$config.deepCopy(this.form))
            console.log(this.formList.length)
            if (this.formList.length >= 20) {
                this.$emit("change", false);
            }
        },

        clearForm(index) {
            if (index >= 0) {
                this.formList[index].enumList = [{key: "", name: ""}];
                this.formList[index].startNum = undefined;
                this.formList[index].endNum = undefined;
                this.formList[index].step = undefined;
                this.formList[index].unit = "";
                this.formList[index].string = undefined;
                this.formList[index].remarks = "";
                this.formList[index].rate = this.formList[index].format === 0 ? 0 : null;

                if (this.formList[index].format === 3 || this.formList[index].format === 7) {
                    this.formList[index].typeBytes = 1;
                } else if (this.formList[index].format === 4 || this.formList[index].format === 0){
                    this.formList[index].typeBytes = 1;
                } else {
                    this.formList[index].typeBytes = null;
                }
                console.log(this.formList[index])
                return;
            }
            this.formList = [this.$config.deepCopy(this.form)];
            this.$refs['ruleForm'][0].resetFields();
        },

        async getFormList(index) {
            console.log(index, index >= 0)
            let list = [];
            let isStop = false;
            let number = [];
            this.formList.forEach((item, i) => {

                if (index >= 0 && index != i) return;

                let enumValues = [];
                item.err = {
                    valueErr: "",
                    enumErr: "",
                    stringErr: "",
                    stepErr: ""
                }

                if (item.format === 3 || item.format === 7) {
                    item.enumList.forEach(item1 => {
                        if (!item1.name || !item1.key) {
                            item.err.enumErr = this.$i18n.t('product.text96');
                            isStop = true;
                            number.push(i);
                        }
                        if (!(/^[a-z_0-9]*$/.test(item1.key))) {
                            item.err.enumErr = this.$i18n.t('product.text69');
                            isStop = true;
                            number.push(i);
                        }
                        enumValues.push({
                            [item1.key]: item1.name
                        });
                    })
                }

                if (item.format === 0 && (item.step === 0)) {
                    item.err.stepErr = this.$i18n.t('product.text97');
                    isStop = true;
                    number.push(i);
                }

                if (item.format === 0 && (item.step === undefined)) {
                    item.err.stepErr = this.$i18n.t('product.text94');
                    isStop = true;
                    number.push(i);
                }

                if ((item.format === 0) && (item.startNum >= item.endNum)) {
                    item.err.valueErr = this.$i18n.t('product.text98');
                    isStop = true;
                    number.push(i);
                }

                if (item.format === 0 && (item.startNum === undefined || item.endNum === undefined)) {
                    item.err.valueErr = this.$i18n.t('product.text99');
                    isStop = true;
                    number.push(i);
                }

                if ((item.format === 5 || item.format === 6) && item.string === undefined) {
                    item.err.stringErr = this.$i18n.t('product.text100');
                    isStop = true;
                    number.push(i);
                }

                list.push({
                    attributeKey: item.attributeRemarks,
                    name: item.attributeName,
                    dataType: item.format,
                    minValue: item.format === 0 ? item.startNum : undefined,
                    maxValue: item.format === 0 ? item.endNum : undefined,
                    stepValue: item.format === 0 ? item.step : undefined,
                    maxLength: item.string,
                    unit: item.unit,
                    remark: item.remarks,
                    typeBytes: item.typeBytes,
                    rate: item.rate,
                    // authority: item.authority,
                    enumValues: JSON.stringify(enumValues)
                })
            });

            for (let i = 0; i < this.$refs['ruleForm'].length; i++) {
                if (index >= 0 && index != i) continue;
                await this.$refs['ruleForm'][i].validate((valid) => {
                    if (!valid) {
                        isStop = true;
                        number.push(i);
                    }
                })
            }

            //当提交验证表单时，对已存在的错误提示进行滚动定位，页面会准确滚动定位到第一个出现校验提示的表单处
            if ((index === null || index === undefined) && isStop) {
                console.log(number);
                number = number.sort((x, y) => x - y);
                let div = document.getElementsByClassName('el-drawer__body')[0];
                let card = document.getElementById('card' + number[0]);
                //45.2为整个弹窗标题高度，减去这个高度能使表单不被标题遮挡
                div.scrollTo(0,card.offsetTop - 45.2);
            }
            if (isStop) return ""

            return JSON.stringify(list);
        },

        delCard(index) {
            this.formList.splice(index, 1);

            if (this.formList.length < 20) {
                this.$emit("change", true);
            }
        },

        addEmun(index) {
            this.formList[index].enumList.push({
                name: "",
                key: ""
            })
        },

        delEmun(index, index1) {
            this.formList[index].enumList.splice(index1, 1);
        }

    }

}
</script>

<style scoped>
.el-radio {
    margin-top: 10px;
}

.el-card {
    margin-top: 10px;
    padding-top: 10px;
}

.el-card:first-child {
    margin-top: 0px;
}

.el-card:last-child {
    margin-bottom: 90px;
}

/deep/ .el-card__header {
    padding: 0 10px 0 0;
    border: none;
    text-align: right;
}

/deep/ .el-input-number--small {
    width: 100%;
    min-width: 80px;
}

/* /deep/ .el-drawer__body{
    padding: 25px;
} */
.add_enum {
    text-align: center;
}

.emun_item {
    /* margin-top: 10px; */
    line-height: 50px;
}

.attribute_alicon {
    color: #3296FA;
    font-size: 16px;
    position: relative;
    top: 1px;
    cursor: pointer;
}

.attribute_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    top: 1px;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

</style>
