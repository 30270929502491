<template>
    <el-main>
        <product-main></product-main>
    </el-main>
</template>

<script>
import {mapState, mapActions} from "vuex";
import productMain from '@/views/product/components/productMain';

export default {

    components: {
        productMain
    },

    data() {
        return {}
    },

    mounted() {
        // this.getProductDetail({
        //     productCode: this.$route.query.id,
        //     isRefresh: true
        // })
    },

    methods: {

        ...mapActions('product', ['getProductDetail'])

    }

}
</script>

<style scoped>
.el-main {
    padding: 0px;
}

.shadow {
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    z-index: 20;
    padding: 0px;
    height: 48px !important;
}

.main-container {
    height: calc(100% - 500px);
    background-color: #E8ECF0;
    position: relative;
    top: 0;
    bottom: 0;
}
</style>
