<template>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$i18n.t('product.text174')"
        :visible.sync="visible"
        width="40%"
        @close="closeDialog"
        @open="openDialog">

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" class="firmware_form"
                 :label-width="formLabelWidth">

            <el-form-item :label="$i18n.t('product.text167')+'：'" prop="panelVersion">
                <el-input
                    v-model.trim="form.panelVersion"
                    :placeholder="$i18n.t('product.text139')">
                </el-input>
            </el-form-item>

            <el-form-item :label="$i18n.t('product.text175')+'：'" required :error="err">

                <el-row>
                    <el-col :span="8">
                        <div class="upload-title">{{$i18n.t('product.text176')}}</div>
                        <br/>
                        <el-upload
                            ref="upload"
                            class="upload-div"
                            :action="$message.uploadImgUrl"
                            :show-file-list="false"
                            :data="{
                               bucket: 'panel',
                               holdName: 'Y' // 保持上传文件名不变
                            }"
                            :headers="{
                                accessToken: $token.getToken().accessToken,
                                refreshToken: $token.getToken().refreshToken,
                                language: $message.language
                            }"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-success="($event) => handleSuccess($event, 'android')"
                            :before-upload="($event) => beforeUpload($event, 'android')"
                            v-loading="load.android"
                            accept=".zip">
                            <img src="../../../../../../assets/icon_file.png" class="icon-file" v-if="file.android"/>
                            <i v-else class="el-icon-plus uploader-icon"></i>
                            <!--                    <el-button size="small" type="primary">点击上传</el-button>-->
                        </el-upload>
                    </el-col>
                    <el-col :span="8">
                        <div class="upload-title">{{$i18n.t('product.text177')}}</div>
                        <br/>
                        <el-upload
                            ref="upload"
                            class="upload-div"
                            :action="$message.uploadImgUrl"
                            :show-file-list="false"
                            :data="{
                               bucket: 'panel',
                               holdName: 'Y' // 保持上传文件名不变
                            }"
                            :headers="{
                                accessToken: $token.getToken().accessToken,
                                refreshToken: $token.getToken().refreshToken,
                                language: $message.language
                            }"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-success="($event) => handleSuccess($event, 'ios')"
                            :before-upload="($event) => beforeUpload($event, 'ios')"
                            v-loading="load.ios"
                            accept=".zip">
                            <img src="../../../../../../assets/icon_file.png" class="icon-file" v-if="file.ios"/>
                            <i v-else class="el-icon-plus uploader-icon"></i>
                            <!--                    <el-button size="small" type="primary">点击上传</el-button>-->
                        </el-upload>
                    </el-col>
                    <el-col :span="8">
                        <div class="upload-title">{{$i18n.t('product.text178')}}</div>
                        <br/>
                        <el-upload
                            ref="upload"
                            class="upload-div"
                            :action="$message.uploadImgUrl"
                            :show-file-list="false"
                            :data="{
                               bucket: 'panel',
                               holdName: 'Y' // 保持上传文件名不变
                            }"
                            :headers="{
                                accessToken: $token.getToken().accessToken,
                                refreshToken: $token.getToken().refreshToken,
                                 language: $message.language
                            }"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-success="($event) => handleSuccess($event, 'hd')"
                            :before-upload="($event) => beforeUpload($event, 'hd')"
                            v-loading="load.hd"
                            accept=".zip">
                            <img src="../../../../../../assets/icon_file.png" class="icon-file" v-if="file.hd"/>
                            <i v-else class="el-icon-plus uploader-icon"></i>
                            <!--                    <el-button size="small" type="primary">点击上传</el-button>-->
                        </el-upload>
                    </el-col>
                </el-row>
            </el-form-item>

            <el-form-item :label="$i18n.t('product.text121')+'：'" prop="log">
                <el-input
                    type="textarea"
                    v-model.trim="form.log"
                    :rows="4"
                    show-word-limit
                    maxlength="300"
                    :placeholder="$i18n.t('product.text145')">
                </el-input>
            </el-form-item>

        </el-form>

        <div class="demo-drawer__footer" style="text-align: right;margin-top: 50px">
            <el-button @click="closeDialog">{{$i18n.t('product.text63')}}</el-button>
            <el-button type="primary" :loading="loading" @click="submit">{{$i18n.t('product.text65')}}</el-button>
        </div>

    </el-dialog>

</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    name: "addPanelDialog",

    computed: {
        ...mapState('panel', ['addPanelDialogVisible']),
        ...mapState('product', ['productMsg'])
    },

    watch: {

        addPanelDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        //产品详情
        productMsg: {
            handler(newVal) {
                this.pid = newVal.pid;
            },
            deep: true
        }

    },

    data() {
        let validateVersion = (rule, value, callback) => {
            if (this.version.ios && value !== this.version.ios) {
                callback(new Error(this.$i18n.t('product.text179')));
            } else if (this.version.android && value !== this.version.android) {
                callback(new Error(this.$i18n.t('product.text179')));
            } else if (this.version.hd && value !== this.version.hd) {
                callback(new Error(this.$i18n.t('product.text179')));
            } else {
                callback();
            }
        };

        return {

            visible: false,

            loading: false,

            form: {
                panelVersion: "",
                pid: "",
                log: ""
            },

            pid: "",

            err: "",

            rules: {
                panelVersion: [
                    {required: true, message: this.$i18n.t('product.text180'), trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: this.$i18n.t('product.text23')},
                    {pattern: /^[a-zA-Z0-9-.]*$/, message: this.$i18n.t('product.text144')},
                    { validator: validateVersion, trigger: 'blur' }
                ],
                log: [
                    {required: true, message: this.$i18n.t('product.text145'), trigger: "blur"}
                ]
            },

            file: {
                ios: "",
                android: "",
                hd: ""
            },

            load: {
                ios: false,
                android: false,
                hd: false
            },

            version: {
                ios: "",
                android: "",
                hd: ""
            },

            formLabelWidth: "100px",
            language: localStorage.getItem('language')

        }

    },

    methods: {

        ...mapActions('panel', ['closeAddPanelDialog', 'addApply', 'appLyAddCheck']),

        openDialog() {
            this.formLabelWidth = this.language === 'zh' ? '100px' : '200px'
            this.pid = this.productMsg.pid;
        },

        closeDialog() {
            this.closeAddPanelDialog();
            this.$refs.ruleForm.resetFields();
            this.form = {
                panelVersion: "",
                log: ""
            };
            this.version = {
                ios: "",
                android: "",
                hd: ""
            }
            this.file = {
                ios: "",
                android: "",
                hd: ""
            }
            this.err = "";
        },

        beforeUpload(file, platform) {
            let fileNameList = file.name.split('_');
            if (fileNameList.length !== 4) {
                this.err = this.$i18n.t('product.text181');
                // this.$dialog.showMessage('RN包名不正确', this.$config.TOAST_WARNING, 5000);
                return false;
            }

            if (fileNameList[0] !== `${platform}RnApp`) {
                this.err = `${this.$i18n.t('product.text182')}${platform}${this.$i18n.t('product.text183')}`;
                return false;
            }

            if (platform === 'android' && this.version.ios && this.version.ios !== fileNameList[1]) {
                this.err = `${this.$i18n.t('product.text184')}`;
                return false;
            }

            if (platform === 'ios' && this.version.android && this.version.android !== fileNameList[1]) {
                this.err = `${this.$i18n.t('product.text185')}`;
                return false;
            }

            if (platform === 'hd' && this.version.hd && this.version.hd !== fileNameList[1]) {
                this.err = `${this.$i18n.t('product.text185')}`;
                return false;
            }

            if (this.pid !== fileNameList[2]) {
                this.err = `${this.$i18n.t('product.text186')}${platform}${this.$i18n.t('product.text187')}`;
                return false;
            }

            this.load[platform] = true;
            this.version[platform] = fileNameList[1];
            this.form.panelVersion = fileNameList[1];
            this.form.pid = fileNameList[2];
            return true;
        },

        handleSuccess(res, platform) {
            console.log(res, platform)
            this.load[platform] = false;
            if (res.success) {
                this.file[platform] = res.result.fileList[0].url;
                this.err = ""
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                this.$refs.upload.clearFiles();
            }
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },

        handlePreview(file) {
            console.log('handlePreview =>', file);
        },

        submit() {
            let isStop = false;
            if (!this.file.ios) {
                this.err = this.$i18n.t('product.text188');
                isStop = true;
            }
            if (!this.file.android) {
                this.err = this.$i18n.t('product.text189');
                isStop = true;
            }
            if (!this.file.hd) {
                this.err = this.$i18n.t('product.text190');
                isStop = true;
            }

            this.$refs.ruleForm.validate((valid) => {
                if (!isStop && valid) {
                    this.appLyAddCheck({
                        name: this.form.panelVersion,
                        productCode: this.$route.query.id,
                        pid: this.form.pid
                    }).then(res => {
                        if (res.success) {
                            this.err = "";
                            this.loading = true;
                            this.addApply({
                                name: this.form.panelVersion,
                                productCode: this.$route.query.id,
                                remark: this.form.log,
                                url: this.file.android,
                                iosUrl: this.file.ios,
                                hdUrl: this.file.hd
                            }).then(res => {
                                this.$dialog.showMessage(this.$i18n.t('product.text148'), this.$config.TOAST_SUCCESS);
                                this.$emit('getMsg');
                                this.closeDialog();
                            }, err => {
                                console.log(err);
                                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {
                            this.$dialog.showMessage(this.$i18n.t('product.text191'), this.$config.TOAST_WARNING, 5000);
                        }
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                }
            })
        }

    }

}
</script>

<style scoped>

.firmware_form {
    margin-top: 20px;
}

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.upload-title {
    float: left;
    font-weight: 400;
    font-size: 12px;
}

.upload-div {
    float: left;
}

/deep/ .upload-div .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    width: 90px;
    height: 90px;
    position: relative;
    overflow: hidden;
}
/deep/ .upload-div .el-upload:hover {
    border-color: #409EFF;
}
.uploader-icon {
    font-size: 28px;
    color: #8c939d;
    line-height: 90px;
    text-align: center;
}

.icon-file {
    width: 50px;
    height: 50px;
    margin-top: 20px;
}

</style>
