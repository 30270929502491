<template>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$i18n.t('product.text29')"
        :visible.sync="visible"
        :width="(language === 'zh' ? 30 : 40) + '%'"
        @close="closeDialog"
        @open="openDialog">

        <el-form :model="form" size="small" ref="ruleForm" class="basic_form" :label-width="formLabelWidth">

            <el-form-item label="PID：" style="margin-bottom: 0;">
                <span id="pid_dialog">{{ form.pid }}</span>
                <i class="el-icon-copy-document copy_dialog" data-clipboard-target="#pid_dialog"></i>
            </el-form-item>

            <el-form-item :label="$i18n.t('product.text30')" style="margin-bottom: 11px;">
                {{ $config.getMsgItemUtil($message.productStatusList, form.state, 'id', 'name') }}
            </el-form-item>

            <el-form-item :label="$i18n.t('product.text15')">
                {{ form.productName }}
            </el-form-item>

            <el-form-item :label="$i18n.t('product.text3')">
                {{ form.model }}
            </el-form-item>

            <el-form-item :label="$i18n.t('product.text31')">
                {{ selectMsg.name }}
            </el-form-item>

            <el-form-item :label="$i18n.t('product.text5')">
                {{ $config.getMsgItemUtil($message.protocolTypeList, form.communication, 'id', 'name') }}
            </el-form-item>

            <el-form-item :label="$i18n.t('product.text19')" v-show="form.networkModeList.length > 0">
                {{ form.networkModeList.join('、') }}
            </el-form-item>

            <el-form-item :label="$i18n.t('product.text20')">

                <div class="category_item">
                    <div class="image">
                        <!--                        <img :src="form.productImageUrl" width="94" height="94">-->

                        <el-image
                            style="width: 94px;height: 94px;"
                            :src="form.productImageUrl"
                            :preview-src-list="[form.productImageUrl]">
                            <div slot="error">
                                <div class="image_slot">
                                    {{$i18n.t('product.text2')}}
                                </div>
                            </div>
                        </el-image>
                    </div>
                </div>

            </el-form-item>

        </el-form>

    </el-dialog>

</template>

<script>

import {mapState, mapActions} from "vuex";
import Clipboard from "clipboard";

export default {

    name: "productMsgDialog",

    computed: {
        ...mapState('product', ['productMsgDialogVisible', 'productMsg']),
    },

    watch: {

        productMsgDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        productMsg: {
            handler(newVal) {
                this.form = {
                    pid: newVal.pid,
                    state: newVal.status,
                    productName: newVal.name,
                    communication: newVal.protocolType,
                    remarks: newVal.remark,
                    productImageUrl: newVal.icon,
                    model: newVal.model,
                    networkModeList: []
                }
                let networkModeList = [];
                newVal.networkModeList && newVal.networkModeList.forEach(item => {
                    networkModeList.push(item.networkModeTypeNameObj.zh)
                })
                this.form.networkModeList = networkModeList;
                this.selectMsg = {
                    name: newVal.categoryName ? (this.language === 'zh' ? JSON.parse(newVal.categoryName).zh : JSON.parse(newVal.categoryName).en ) : "",
                    iconUrl: newVal.categoryIconUrl
                }

                console.log("productMsg:", newVal)
            },
            deep: true
        }

    },

    data() {

        return {

            formLabelWidth: "120px",

            visible: false,

            loading: false,

            selectMsg: {},

            form: {
                pid: "",
                state: 1,
                productName: "",
                productType: "",
                communication: 3,
                remarks: "",
                productImageUrl: "",
                model: "",
                networkModeList: []
            },

            clipboard: null,
            language: localStorage.getItem('language')

        }

    },

    methods: {

        ...mapActions('product', ['closeProductDialog', 'getProductDetail']),

        // getMsg() {
        //     this.getProductDetail({
        //         productCode: this.$route.query.id,
        //         isRefresh: false
        //     });
        // },

        openDialog() {
            // this.getMsg();
            this.formLabelWidth = this.language === 'zh' ? '120px' : '240px'
            this.clipboard = new Clipboard('.copy_dialog');
            this.clipboard.on('success', (e) => {
                this.$dialog.showMessage(this.$i18n.t('product.text13'), this.$config.TOAST_SUCCESS);
            });
        },

        closeDialog() {
            this.closeProductDialog();
            this.clipboard.destroy();
        }

    }

}
</script>

<style scoped>

.basic_form {
    margin-top: 20px;
}

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.category_item {
    margin-bottom: 10px;
    text-align: center;
    padding-top: 10px;
}


.image {
    position: relative;
    width: 94px;
    height: 94px;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px;

}

.image_slot {
    background-color: #F7F7F7;
    width: 94px;
    height: 94px;
    font-size: 14px;
    line-height: 94px;
    color: #c0c4cc;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
}

.el-select, .el-input, .el-textarea {
    width: 300px;
}

.avatar-uploader .el-upload, .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover, .avatar-uploader-icon:hover {
    border-color: #1c204f;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
}

.edit_btn {
    margin-left: 120px;
}

.product_card {
    width: 100px;
    text-align: center;
}

.card_title {
    text-align: center;
    padding: 0 5px 5px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.clean_btn {
    margin: 5px 5px -10px 0;
    padding: 0 !important;
    float: right;
    position: relative;
    z-index: 999;
}

.copy_dialog {
    cursor: pointer;
    margin-left: 6px;
}

.copy_dialog:hover {
    color: #3296FA;
}
</style>
