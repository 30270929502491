<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$i18n.t('product.text58')"
        :visible.sync="visible"
        width="50%"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-row>
            <el-col :span="11">
                <div class="effect_list">
                    <div class="title">
                        {{$i18n.t('product.text109')}}
                    </div>
                    <el-checkbox-group v-model="selectMsg" v-if="leftList.length > 0">
                        <el-checkbox :label="item.serviceId" v-for="(item, index) in leftList" :key="index" :class="selectMsg.indexOf (item.serviceId) !== -1 ? 'action' : ''">
                            <div class="name">{{item.name}}</div>
                            <div class="msg">{{item.description}}</div>
                            <div class="msg">service_id:{{item.serviceId}}</div>
                            <div class="msg">{{$i18n.t('product.text110')}}:{{item.serviceKey}}</div>
                        </el-checkbox>
                    </el-checkbox-group>

                    <el-empty :description="$i18n.t('product.text111')" :image-size="100" v-else></el-empty>
                </div>
            </el-col>
            <el-col :span="2" style="text-align: center;margin-top: 190px;">
                <el-button
                    icon="el-icon-arrow-right"
                    size="mini"
                    @click="select"
                    :disabled="leftList.length === 0"
                    style="width: calc(100% - 12px); margin-left: 2px;"/>
            </el-col>
            <el-col :span="11">
                <div class="effect_list">
                    <div class="title">
                        {{$i18n.t('product.text112')}}
                    </div>
                    <div class="list" v-if="rightList.length > 0">
                        <el-row class="item" v-for="(item, index) in rightList" :key="index">
                            <el-col :span="2">
                                <el-button type="text" icon="el-icon-delete" style="color: #FF2825;margin-top: -10px;" @click="del(item)"></el-button>
                            </el-col>
                            <el-col :span="22">
                                <div class="name">{{item.name}}</div>
                                <div class="msg">{{item.description}}</div>
                                <div class="msg">service_id:{{item.serviceId}}</div>
                                <div class="msg">{{$i18n.t('product.text110')}}:{{item.serviceKey}}</div>
                            </el-col>
                        </el-row>
                    </div>

                    <el-empty :description="$i18n.t('product.text113')" :image-size="100" v-else></el-empty>
                </div>
            </el-col>
        </el-row>


        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">{{$i18n.t('product.text63')}}</el-button>
            <el-button type="primary" :loading="loading" @click="submit">{{$i18n.t('product.text105')}}</el-button>
        </span>

    </el-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {

    name: "addStandardFunctionsDialog",

    computed: {
        ...mapState('effect', ['addStandardFunctionsDialogVisible']),
        ...mapState('product', ['productMsg'])
    },

    watch: {

        addStandardFunctionsDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        productMsg: {
            handler(newVal) {
                this.productData = newVal;
            }
        }

    },

    data() {

        return {

            visible: false,

            loading: false,

            selectMsg: [],

            productData: {},

            leftList: [],

            rightList: []

        }

    },

    methods: {

        ...mapActions('effect', ["closeAddStandardFunctionsDialog", "getStandardServiceList", "addFromStandard"]),

        getStandardList() {
            this.loadingStandard = true;
            this.getStandardServiceList({
                productCode: this.$route.query.id,
                categoryCode: this.productData.categoryCode
            }).then(res => {
                if (!res.result) return;
                let list = [];
                //过滤以选项
                res.result.forEach(item => {
                    if (!item.selected) {
                        list.push(item);
                    }
                })
                this.leftList = list.sort((x, y) => x['serviceId'] - y['serviceId']);
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loadingStandard = false;
            })
        },

        select() {
            this.selectMsg.forEach((val, i) => {
                this.leftList.forEach((item, index) => {
                    if (val === item.serviceId) {
                        this.leftList.splice(index, 1);
                        this.rightList.push(item);
                    }
                })
            })
            this.selectMsg = []
        },

        del(item) {
            this.rightList.forEach((val, index) => {
                if (val.serviceId === item.serviceId) {
                    this.rightList.splice(index, 1);
                    this.leftList.push(item);
                    this.leftList = this.leftList.sort((x, y) => x['serviceId'] - y['serviceId']);
                }
            })
        },

        submit() {
            let list = [];
            this.rightList.forEach(item => list.push(item.serviceId))
            if (list.length === 0) {
                this.$dialog.showMessage(this.$i18n.t('product.text114'), this.$config.TOAST_WARNING);
                return
            }
            this.loading = true;
            this.addFromStandard({
                categoryCode: this.productData.categoryCode,
                productCode: this.$route.query.id,
                serviceIds: list.join(',')
            }).then(res => {
                this.$dialog.showMessage(this.$i18n.t('product.text70'), this.$config.TOAST_SUCCESS);
                this.$emit("getMsg");
                this.$emit("getStandardList")
                this.closeDialog();
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })

        },

        openDialog() {
            this.productData = this.productMsg;
            this.getStandardList();
        },

        closeDialog() {
            this.closeAddStandardFunctionsDialog();
            this.rightList = [];
            this.leftList = [];
            this.selectMsg = [];
        }

    }

}
</script>

<style scoped>
.effect_list {
    width: calc(100%);
    border: 1px solid #DCDCDC;
    border-radius: 3px;
}
.title {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0,0,0,0.90);
    padding: 10px;
    border-bottom: 1px solid #DCDCDC;
}
/deep/ .el-icon-arrow-right {
    font-weight: bold;
}

.el-checkbox, .item {
    width: calc(100%);
    margin-bottom: 10px;
}

.el-checkbox-group, .list {
    padding: 10px;
    height: 340px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.el-empty {
    padding: 96px 0;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: #f2f2f2;
    padding-right: 2px;
}

/deep/ .el-checkbox__input {
    position: absolute;
    top: 2px;
}


/deep/ .el-checkbox__label {
    width: 90%;
    margin-left: 12px;
}

.name {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.90);
}

/deep/ .el-checkbox, .el-checkbox__input {
    white-space: break-spaces!important;
}

/deep/ .el-checkbox{
    width: calc(100% - 10px);
    padding: 5px;
}

.action {
    background: rgba(24,144,255,0.08);
}

/deep/ .el-checkbox__inner {
    margin-top: 3px;
}

.msg {
    font-weight: 400;
    font-size: 12px;
    color: rgba(0,0,0,0.65);
    width: 100%;
    word-break:break-all; /*支持IE，chrome，FF不支持*/
    word-wrap:break-word;/*支持IE，chrome，FF*/

}
</style>
