<template>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$i18n.t('product.text161')"
        :visible.sync="visible"
        width="35%"
        @close="closeDialog"
        @open="openDialog">

        <div style="max-height: 300px;overflow-y: auto;margin-top: 10px;">

            <div class="module_item" v-for="(item, index) in list" :key="index">

                <el-row>

                    <el-col :span="4">
                        <el-image :src="item.icon" class="product_img">
                            <div slot="error">
                                <div class="image_slot">
                                    {{$i18n.t('product.text150')}}
                                </div>
                            </div>
                        </el-image>
                    </el-col>

                    <el-col :span="16">
                        <el-descriptions title="" :column="2" style="margin-top: 13px;">
                            <el-descriptions-item :label="$i18n.t('product.text151')">{{ item.model }}</el-descriptions-item>
                            <el-descriptions-item :label="$i18n.t('product.text152')">{{ item.chip }}</el-descriptions-item>
                            <el-descriptions-item :label="$i18n.t('product.text153')">
                                {{ $config.getMsgItemUtil($message.antennaTypeList, item.antenna, 'id', 'name') }}
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>

                    <el-col :span="4">
                        <el-radio v-model="selectModule" :label="item.code">{{ "" }}</el-radio>
                    </el-col>

                </el-row>

            </div>

        </div>

        <div class="demo-drawer__footer" style="text-align: right;margin-top: 20px">
            <el-button @click="closeDialog">{{$i18n.t('product.text63')}}</el-button>
            <el-button type="primary" :loading="loading" @click="submit">{{$i18n.t('product.text65')}}</el-button>
        </div>

    </el-dialog>
</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    name: "moduleDialog",

    computed: {
        ...mapState('firmware', ['moduleDialogVisible', 'selectModuleId', 'moduleList']),
        ...mapState('product', ['productMsg'])
    },

    watch: {

        moduleDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        selectModuleId: {
            handler(newVal) {
                this.selectModule = newVal;
            }
        },

        moduleList: {
            handler(newVal) {
                this.list = newVal;
            }
        },

        productMsg: {
            handler(newVal) {
                this.productData = newVal;
            }
        }

    },

    data() {

        return {

            visible: false,

            loading: false,

            list: [],

            selectModule: "",

            productData: {}

        }

    },

    methods: {

        ...mapActions('firmware', ['closeModuleDialog', 'queryModuleList']),
        ...mapActions('product', ['modifyProduct']),

        closeDialog() {
            this.closeModuleDialog();
            this.selectModule = "";
        },

        openDialog() {
            this.selectModule = this.selectModuleId;
            this.productData = this.productMsg;

            console.log(this.selectModule)
        },

        submit() {
            this.loading = true;
            this.modifyProduct({
                code: this.productData.code,
                // name: this.productData.name,
                moduleCode: this.selectModule
            }).then(res => {
                this.$store.commit('firmware/SET_SELECT_MODULE_ID', this.selectModule);
                this.closeDialog();
                this.$dialog.showMessage(this.$i18n.t('product.text51'), this.$config.TOAST_SUCCESS);
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        }

    }

}
</script>

<style scoped>

.module_item {
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
}

.module_item:first-child {
    margin-top: -10px;
}

.product_img {
    width: 60px;
    height: 60px;
    background-color: #f7f7f7;
    border-radius: 8px;
    margin: 10px 0;
}

.image_slot {
    background-color: #F7F7F7;
    width: 50px;
    height: 50px;
    font-size: 12px;
    text-align: center;
    padding: 13px 5px 0 5px;
    color: #c0c4cc;
}

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.el-radio {
    margin-top: 31px;
    float: right;
}

/deep/ .el-radio__inner {
    border-radius: 20px;
    width: 18px;
    height: 18px;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    background: #FFFFFF;
}

/deep/ .el-radio__inner::after {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #1890FF;
}


</style>
